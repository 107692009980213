import "../scss/theme.scss";
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/modal";
import "./sticky-header.js";
require("slick-carousel/slick/slick.scss");
require("slick-carousel/slick/slick-theme.scss");
import "slick-carousel";

//images
import "../image/phone.svg";
import "../image/location.svg";
import "../image/play.svg";

jQuery(document).ready(function(jQuery) {
	//Elements to inject
	var elementsToInject = document.querySelectorAll(".inject-me");
	// Options
	var injectorOptions = {
		evalScripts: "once",
	};
	// Trigger the injection
	var injector = new SVGInjector(injectorOptions);
	if (jQuery(elementsToInject).length) {
		injector.inject(elementsToInject);
	}

	const $dropdown = jQuery(".dropdown");
	const $dropdownToggle = jQuery(".dropdown-toggle");
	const $dropdownMenu = jQuery(".dropdown-menu");
	const showClass = "show";

	jQuery(window).on("load resize", function() {
		if (this.matchMedia("(min-width: 768px)").matches) {
			$dropdown.hover(
				function() {
					const $this = jQuery(this);
					$this.addClass(showClass);
					$this.find($dropdownToggle).attr("aria-expanded", "true");
					$this.find($dropdownMenu).addClass(showClass);
				},
				function() {
					const $this = jQuery(this);
					$this.removeClass(showClass);
					$this.find($dropdownToggle).attr("aria-expanded", "false");
					$this.find($dropdownMenu).removeClass(showClass);
				}
			);
		} else {
			$dropdown.off("mouseenter mouseleave");
		}
	});

	jQuery('a[href="#contact"]').click(function() {
		jQuery("#modal").modal("show");
	});

	if (window.location.href.indexOf("#contact") != -1) {
		jQuery("#modal").modal("show");
	}

	jQuery(".logos-slider").slick({
		lazyLoad: "ondemand",
		speed: 300,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnFocus: false,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 6,
		centerMode: false,
		arrows: false,

		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
		],
	});

	jQuery(".employee-slider").slick({
		infinite: true,
		dots: true,
		customPaging: function(slider, i) {
			var thumb = jQuery(slider.$slides[i]).data();
			return '<a class="dot">' + (i + 1) + "</a>";
		},
		prevArrow: jQuery(".employee-slider-prev"),
		nextArrow: jQuery(".employee-slider-next"),
	});
	jQuery(".logo-marquee .marquee").slick({
		speed: 5000,
		autoplay: true,
		autoplaySpeed: 0,
		centerMode: true,
		cssEase: "linear",
		slidesToShow: 3,
		slidesToScroll: 1,
		variableWidth: true,
		infinite: true,
		initialSlide: 1,
		arrows: false,
		buttons: false,
		pauseOnHover: true,
	});
	jQuery(".photo-marquee-slider").slick({
		speed: 7000,
		autoplay: true,
		autoplaySpeed: 0,
		centerMode: true,
		cssEase: "linear",
		slidesToShow: 5,
		slidesToScroll: 1,
		variableWidth: true,
		infinite: true,
		initialSlide: 1,
		arrows: false,
		buttons: false,
		pauseOnHover: true,
	});

	jQuery(".responsive-tabs").responsiveTabs({
		startCollapsed: "accordion",
		setHash: false,
	});
});

jQuery.fn.exists = function(callback) {
	var args = [].slice.call(arguments, 1);

	if (this.length) {
		callback.call(this, args);
	}
	return this;
};

jQuery(document).ready(function() {
	jQuery(document).on("fancybox-cleanup", function() {
		jQuery("video,audio").trigger("pause");
	});
	jQuery(".member-play").on("click", function() {
		let vidTrigger = this.getAttribute("data-id");
		let video = document.querySelector(`#${vidTrigger} video`);
		console.log(video);
		jQuery(video).trigger("play");
	});
});
