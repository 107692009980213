jQuery(document).ready(function (jQuery) {
	let sticky = jQuery('.fixed-top');
	jQuery(window).scroll(function () {
		let scroll = jQuery(window).scrollTop();
		if (scroll >= 190) {
			sticky.addClass('stuck');
		} else {
			sticky.removeClass('stuck');
		}
	});
});
	